
(function ($) {
    $(document).on("turbolinks:load", function () {
     
        $('#physical_tag_physically_taggable_type').off('change').on('change', function () {
            var type_klass = $(this).val();
            $("#physical_tag_physically_taggable_id").trigger('change');

            $("#physical_tag_physically_taggable_id").select2({
                ajax: {
                    url: $(this).data('other-ajax-url'),
                    dataType: 'json',
                    data: function (params) {
                        return {
                            term: params.term,
                            type_klass: type_klass,
                            page: params.page || 1, 
                            pageSize: 10 
                        };
                    },
                    processResults: function (data, params) {
                        params.page = params.page || 1; 

                        return {
                            results: data.options, 
                            pagination: {
                                more: data.more 
                            }
                        };
                    }
                },
            });
        });

        // Trigger iniziale (opzionale)
        $('#physical_tag_physically_taggable_type').trigger('change');
    });
})(jQuery);